<template>
  <div class="auth-side-item d-none d-lg-block">
    <AuthNav />
    <div class="auth-side-item__content pt-5 pb-3">
      <h1 class="text__title text--white" :class="{ fade: transitioning }">
        {{ currentTitle }}
      </h1>
    </div>
    <div class="auth-side-item__img-wrapper">
      <img
        :class="['auth-side-item__img', { fade: transitioning }]"
        :src="currentImage"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import AuthNav from "@/components/Auth/AuthNav.vue";

export default {
  name: "SideItem",
  components: {
    AuthNav,
  },
  data() {
    return {
      images: [
        require("@/assets/img/auth/intro_1.png"),
        require("@/assets/img/auth/intro_2.png"),
        require("@/assets/img/auth/intro_3.png"),
      ],
      titles: [
        "The only Customer Support tool, you’ll ever need",
        "Effortlessly connect with customers on WhatsApp",
        "Unlock seamless capabilities with our API",
      ],
      currentImageIndex: 0,
      currentTitleIndex: 0,
      transitioning: false,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    currentTitle() {
      return this.titles[this.currentTitleIndex];
    },
  },
  mounted() {
    setInterval(this.changeContent, 5000);
  },
  methods: {
    changeContent() {
      this.transitioning = true;
      setTimeout(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
        this.currentTitleIndex =
          (this.currentTitleIndex + 1) % this.titles.length;
        this.transitioning = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.auth-side-item {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: var(--wb-green-light);
  padding: 1rem;
  overflow: hidden;
}
.auth-side-item .auth-side-item__img-wrapper {
  position: absolute;
  bottom: 1rem;
  right: 0;
  max-width: 600px;
}
.auth-side-item .auth-side-item__img-wrapper img {
  width: 100%;
  object-fit: contain;
  transition: opacity 0.5s;
  opacity: 1;
}
.auth-side-item .auth-side-item__content {
  max-width: 600px;
  margin: 0 auto;
}
.auth-side-item .auth-side-item__content h1 {
  transition: opacity 0.5s;
  opacity: 1;
}
.auth-side-item .auth-side-item__content h1.fade,
.auth-side-item .auth-side-item__img-wrapper img.fade {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
