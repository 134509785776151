export const MessageRoutes = [
  {
    path: "/u/messages/inbox",
    name: "Inbox",
    component: () => import("@/views/Dashboard/Messages/Inbox.vue"),
  },
  {
    path: "/u/messages/outbox",
    name: "Outbox",
    component: () => import("@/views/Dashboard/Messages/Outbox.vue"),
  },
  {
    path: "/u/messages/send",
    name: "SendMessage",
    component: () => import("@/views/Dashboard/Messages/SendMessage.vue"),
  },
  {
    path: "/u/messages/:id",
    name: "SingleMessage",
    component: () => import("@/views/Dashboard/Messages/_id.vue"),
  },
  {
    path: "/u/dashboard/live_chat",
    name: "LiveChat",
    component: () => import("@/views/Dashboard/Messages/LiveChat.vue"),
  },
];
