<template>
  <div
    @click="emitLoading"
    class="text-center cursor--pointer text__body--small text--secondary text--medium bg--fade-grey d-flex justify-content-center"
  >
    <span v-if="!isLoading">{{ loadingMessage }} </span>
    <Spinner variant="dark" :isSmall="true" v-if="isLoading" />
  </div>
</template>

<script>
export default {
  name: "LoadMoreButton",
  props: {
    loadingMessage: {
      type: String,
      default: "Loading",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitLoading() {
      this.$emit("startLoading");
    },
  },
};
</script>

<style></style>
