<template>
  <div class="text-danger text__body--smaller my-2">{{ message }}</div>
</template>

<script>
export default {
  name: "InlineError",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
