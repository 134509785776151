<template>
  <div class="auth-wrapper">
    <div class="row gx-0">
      <div class="col-lg-6">
        <div class="auth-layout-nav">
          <router-link to="/">
            <img
              src="@/assets/brand/logo.svg"
              class="auth-layout-nav__brand"
              alt=""
            />
          </router-link>
          <div class="d-block d-lg-none">
            <AuthNav />
          </div>
        </div>
        <div class="mt-3">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
      <div class="col-lg-6">
        <SideItem />
      </div>
    </div>
  </div>
</template>

<script>
import SideItem from "@/components/Auth/SideItem";
import AuthNav from "@/components/Auth/AuthNav";
export default {
  name: "AuthLayout",
  components: {
    SideItem,
    AuthNav,
  },
};
</script>

<style>
.auth-layout-nav {
  min-height: 50px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth-layout-nav .auth-layout-nav__brand {
  max-width: 180px;
}
</style>
