<template>
  <div
    class="wb-line-loader"
    :class="{
      'wb-line-loader--top': position === 'top',
      'wb-line-loader--bottom': position === 'bottom',
    }"
  ></div>
</template>

<script>
export default {
  name: "LineLoader",
  props: {
    position: {
      type: String,
      default: "top",
    },
  },
};
</script>

<style>
.wb-line-loader {
  position: absolute;
  left: 0;
  height: 4px;
  background: var(--wb-green);
  width: 0;
  -webkit-animation: load 2s infinite;
  animation: load 2s infinite;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.wb-line-loader.wb-line-loader--top {
  top: 0;
}
@-webkit-keyframes load {
  0% {
    width: 0;
  }

  20% {
    width: 16%;
  }

  55% {
    width: 43%;
  }

  69% {
    width: 68%;
  }

  80% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

@keyframes load {
  0% {
    width: 0;
  }

  20% {
    width: 16%;
  }

  55% {
    width: 43%;
  }

  69% {
    width: 68%;
  }

  80% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}
</style>
