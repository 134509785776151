<template>
  <div>
    <div v-if="activeBotInstance">
      <button
        class="wb-bot__btn wb-animated-dropdown"
        :class="{
          'w-100': isFull,
        }"
        type="button"
        id="dropdownMenuButton"
      >
        <div class="d-flex align-items-center me-3 text-start">
          <img
            :src="`https://api.dicebear.com/6.x/shapes/svg?seed=${activeBotInstance.settings.bot_name}&backgroundColor=ffdfbf`"
            class="wb-image-icon wb-image-icon--standard wb-image-icon--bordered wb-image-icon--rounded me-2"
            alt=""
          />
          <div>
            <div @click="showBotsList">
              {{ activeBotInstance.settings.bot_name }}
            </div>
            <div
              class="text__body--smallest"
              :class="{
                'text-info': activeBotInstance.settings.status == 'ONLINE',
                'text-muted': activeBotInstance.settings.status != 'ONLINE',
              }"
              @click="showBotsList"
            >
              {{
                activeBotInstance.settings.status == "ONLINE"
                  ? "Online"
                  : "Offline"
              }}
              <router-link
                to="/u/dashboard/bot/connect"
                class="text__body--smallest text--green text-decoration-none"
                v-if="activeBotInstance.settings.status != 'ONLINE'"
                >- Reconnect
              </router-link>
            </div>
          </div>
        </div>
        <i class="bi bi-chevron-down" @click="showBotsList"></i>
      </button>
    </div>
    <BotsListModal ref="botsList" />
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import BotsListModal from "@/components/Dashboard/Bots/BotsListModal.vue";
export default {
  name: "BotListDropdown",
  components: {
    BotsListModal,
  },
  props: {
    isFull: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const activeBotInstance = ref(null);
    const botsList = ref({});
    const store = useStore();
    activeBotInstance.value = store.getters.activeBotInstance;
    watch([() => store.getters.activeBotInstance], ([newActiveBotInstance]) => {
      activeBotInstance.value = newActiveBotInstance;
    });
    const showBotsList = () => {
      botsList.value.openModal();
    };
    return {
      showBotsList,
      activeBotInstance,
      botsList,
    };
  },
};
</script>

<style></style>
