<template>
  <div>
    <a
      href="javascript:void(0)"
      class="auth-nav-toggle"
      @click="mobileNavOpen = !mobileNavOpen"
    >
      <i :class="[mobileNavOpen ? 'bi bi-x' : 'bi bi-list']"></i>
    </a>
    <div class="auth-side-item__nav">
      <a
        :href="item.path"
        v-for="(item, index) in menuItems"
        :key="index"
        class="auth-side-item__nav-item"
      >
        {{ item.name }}
      </a>
    </div>
    <div class="auth-nav-mobile" v-if="mobileNavOpen" ref="mobileNav">
      <a
        :href="item.path"
        v-for="(item, index) in menuItems"
        :key="index"
        class="auth-nav-mobile__nav-item"
      >
        {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthNav",
  data() {
    return {
      mobileNavOpen: false,
      menuItems: [
        {
          name: "Contact us",
          path: "#!",
        },
        {
          name: "Customer Support",
          path: "#!",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const toggleButton = this.$el.querySelector(".auth-nav-toggle");
      const mobileNavElement = this.$refs.mobileNav;

      if (
        toggleButton &&
        !toggleButton.contains(event.target) &&
        mobileNavElement &&
        !mobileNavElement.contains(event.target) &&
        this.mobileNavOpen
      ) {
        this.mobileNavOpen = false;
      }
    },
  },
};
</script>

<style>
.auth-side-item__nav {
  min-height: 50px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.auth-side-item__nav .auth-side-item__nav-item {
  font-size: 1rem;
  font-weight: var(--fw-medium);
  color: var(--wb-white);
  text-decoration: none;
  display: inline-block;
  margin: 0 1.3rem;
}
.auth-nav-toggle {
  display: none;
  color: var(--wb-dark);
  font-size: 2rem;
}
.auth-nav-mobile {
  position: absolute;
  top: 60px;
  right: 1rem;
  min-height: 100px;
  padding: 1rem;
  width: 12rem;
  background: var(--wb-white);
  box-shadow: var(--wb-shadows-1);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.auth-nav-mobile .auth-nav-mobile__nav-item {
  font-size: 0.875rem;
  font-weight: var(--fw-medium);
  color: var(--wb-green);
  text-decoration: none;
  margin: 0.5rem 0;
}
@media screen and (max-width: 992px) {
  .auth-side-item__nav .auth-side-item__nav-item {
    color: var(--wb-green);
  }
}
@media screen and (max-width: 576px) {
  .auth-side-item__nav {
    display: none;
  }
  .auth-nav-toggle {
    display: block;
  }
}
</style>
