export const ContactRoutes = [
  {
    path: "/u/dashboard/contacts",
    name: "Contacts",
    component: () => import("@/views/Dashboard/Contacts/Contacts.vue"),
  },
  {
    path: "/u/dashboard/contacts/group/:id",
    name: "SingleContactGroup",
    component: () =>
      import("@/views/Dashboard/Contacts/SingleContactGroup.vue"),
  },
];
