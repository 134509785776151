import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAllChats({ commit, state }, payload) {
    try {
      const url = payload.isAll
        ? `/chat?type=all&limit=${payload.limit}`
        : `/chat?type=${payload.type || "all"}&session_id=${
            payload.session_id
          }&access_token=${payload.access_token}&page=${payload.page}&limit=${
            payload.limit
          }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getChatMessages({ commit, state }, payload) {
    try {
      const url = `/chat/${payload.number}?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewChat({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/chat", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async resolveChat({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/chat/resolve", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewChatGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/chat_groups", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteChat({ commit, state }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/chat/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteChatGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/chat_groups/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateChat({ commit, state }, payload) {
    try {
      const response = await axiosInstance.put(
        `/chat/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateChatGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.put(
        `/chat_groups/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
