<template>
  <div class="my-5">
    <div class="text-center">
      <div class="mb-4">
        <i :class="iconClass" class="text__body--x-large text-danger"></i>
      </div>
      <h4 class="text__body text--dark">{{ message }}</h4>
      <p class="text__body--smaller text-muted">
        {{ description }}
      </p>
      <div class="text-center mt-4">
        <a
          href="javascript:void(0)"
          @click="retryRequest()"
          class="text--green text__body--smaller"
          >{{ buttonText }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorOccurred",
  props: {
    message: {
      type: String,
      default: "An error occurred while fetching",
    },
    iconClass: {
      type: String,
      default: "bi bi-cloud-slash",
    },
    description: {
      type: String,
      default: "There was an error during this request",
    },
    buttonText: {
      type: String,
      default: "Try Again",
    },
  },
  methods: {
    retryRequest() {
      this.$emit("retry");
    },
  },
};
</script>

<style></style>
