<template>
  <div class="my-5">
    <div
      class="d-flex align-items-center mb-4 mb-lg-0 flex-column justify-content-between text-center"
    >
      <img
        :src="icon"
        class="wb-image-icon wb-image-icon--larger"
        alt=""
        v-if="!usesLottie"
      />
      <div class="wb-lottie-item wb-lottie-item--small">
        <LottieAnimation
          ref="anim"
          :animationData="lottieJSON"
          :loop="true"
          :speed="1"
          :autoPlay="true"
          v-if="usesLottie"
        />
      </div>
      <h3 class="mb-0 text__body--large text--medium text--dark mt-4 mb-2">
        {{ title }}
      </h3>
      <p class="mb-0 text__body--small text--regular text--secondary">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue";
export default {
  name: "EmptyState",
  components: {
    LottieAnimation,
  },
  props: {
    title: {
      type: String,
      default: "Nothing to see here",
    },
    description: {
      type: String,
      default: "You seem to have nothing to show",
    },
    icon: {
      type: String,
      default: require("@/assets/icons/messages_large.svg"),
    },
    usesLottie: {
      type: Boolean,
      default: false,
    },
    lottieJSON: {
      type: Object,
      default: require("@/assets/lottie/whatsapp.json"),
    },
  },
};
</script>

<style></style>
