<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { useSeoMeta, useHead } from "unhead";
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
export default {
  name: "App",
  setup() {
    useHead({
      titleTemplate: "%s - Waabot",
    });
    useSeoMeta({
      title: "WhatsApp Client API",
      description:
        "Setup AI bot with WhatsApp or send out personalized service notifications and secure one-time passwords (OTP) to existing customers on Whatsapp",
      ogDescription:
        "Setup AI bot with WhatsApp or send out personalized service notifications and secure one-time passwords (OTP) to existing customers on Whatsapp",
      ogTitle: "Waabot - WhatsApp Client API",
      ogImage: "https://example.com/image.png",
      twitterCard: "summary_large_image",
    });
    const store = useStore();
    const currentTheme = computed(() => {
      return store.getters.theme || null;
    });
    onMounted(() => {
      if (currentTheme.value == "dark_theme") {
        document.body.classList.add("dark_theme");
      } else {
        document.body.classList.add("light_theme");
      }
    });
  },
};
</script>
