import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAllMessages({ commit, state }) {
    try {
      const response = await axiosInstance.get("/message-log?type=all");
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAllBotMessages({ commit, state }, payload) {
    try {
      const params = payload.isAll
        ? {
            type: "all",
            limit: payload.limit,
            direction: payload.direction,
            created: payload.created,
            start_date: payload.start_date,
            end_date: payload.end_date,
            status: payload.status,
          }
        : {
            session_id: payload.session_id,
            access_token: payload.access_token,
            page: payload.page,
            limit: payload.limit,
            direction: payload.direction,
            created: payload.created,
            start_date: payload.start_date,
            end_date: payload.end_date,
            status: payload.status,
          };

      const response = await axiosInstance.get("/message-log", { params });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAllBroadcast({ commit, state }, payload) {
    try {
      const params = payload.isAll
        ? {
            type: "all",
            limit: payload.limit,
            direction: payload.direction,
            created: payload.created,
            start_date: payload.start_date,
            end_date: payload.end_date,
            status: payload.status,
          }
        : {
            session_id: payload.session_id,
            access_token: payload.access_token,
            page: payload.page,
            limit: payload.limit,
            direction: payload.direction,
            created: payload.created,
            start_date: payload.start_date,
            end_date: payload.end_date,
            status: payload.status,
          };

      const response = await axiosInstance.get("/broadcast", { params });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async sendTextMessage({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        `/whatsapp/message?session_id=${payload.session_id}&access_token=${payload.access_token}`,
        payload.data
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async sendBroadCastMessage({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(`/broadcast`, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSingleMessage({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/message-log/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async sendTextChat({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(`/chat/sendText`, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
