// main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles";
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import useImports from "@/imports";
const app = createApp(App);
useImports(app);
const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);
app.use(store);
app.use(router);
app.mount("#app");
