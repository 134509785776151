<template>
  <div>
    <div
      class="wb-file-item my-2 cursor--pointer text--secondary-fixed"
      v-if="file"
      @click="removeFile"
    >
      <i :class="file.fileIcon" class="me-2 wb-file-item__file-icon"></i>
      <span>{{ file.filename }}</span>
      <i class="bi bi-x wb-file-item__remove-icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileItem",
  props: {
    index: {
      type: Number,
      default: null,
    },
    file: {
      type: Object,
      default: null,
    },
  },
  methods: {
    removeFile() {
      this.$emit("removeFile", this.index);
    },
  },
};
</script>

<style></style>
