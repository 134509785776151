<template>
  <div
    class="text-center cursor--pointer text__body--small text--secondary text--medium bg--fade-grey d-flex justify-content-center fixed--message align-items-center"
    :class="{
      'fixed--message--bottom': position === 'bottom',
    }"
  >
    {{ message }} <Spinner variant="dark" :isSmall="true" />
  </div>
</template>

<script>
export default {
  name: "FixedMessage",
  props: {
    message: {
      type: String,
      default: "Loading...",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
};
</script>
<style>
.fixed--message {
  position: absolute;
  left: 0;
  width: 100%;
}
.fixed--message.fixed--message--bottom {
  bottom: 1rem;
}
</style>
