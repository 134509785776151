import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async createAttachment({ commit, state }, payload) {
    try {
      if (!payload.file || !(payload.file instanceof File)) {
        throw new Error("Invalid file");
      }

      const formData = new FormData();
      formData.append("session_id", payload.session_id);
      formData.append("access_token", payload.access_token);
      const mimeType = payload.file.type;
      formData.append("mimeType", mimeType);
      formData.append("file", payload.file);
      const response = await axiosInstance.post(`/attachment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
